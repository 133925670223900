/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const TermsContainer = styled.div`
  margin: 0 auto;
  max-width: 1024px;
  padding: 24px;
  img {
    width: 260px;
    display: block;
    margin: 0 auto;
  }
`;
